import React, { useState } from "react"
import useWindowSize from "../hooks/useWindowSize"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide"
import "@splidejs/react-splide/css"

import Layout from "../components/layout"
import ImageView from "../components/imageView"

export default function Brochure() {
  const windowSize = useWindowSize()
  const baseLine =
    windowSize.width > windowSize.height ? windowSize.height : windowSize.width
  const padding = windowSize.width > windowSize.height ? "10rem" : "6rem"
  const imageRatio = 4960 / 3188

  let frameWidth = windowSize.width - 60
  let frameHeight = frameWidth * 0.826
  if (frameHeight > windowSize.height) {
    frameHeight = windowSize.height - 60
    frameWidth = frameHeight / 0.826
  }

  return (
    <div className="flex flex-col max-h-screen h-screen">
      <div className="bg-primary backdrop-filter backdrop-blur-xs bg-opacity-80">
        <div className="flex justify-between p-2 px-4">
          <div className="flex">
            <h2 className="text-sm tracking-wider font-body uppercase tracking-wider">Brochure</h2>
            <a href="/assets/brochure.pdf" target="_blank" className="text-sm font-body tracking-wider px-8">Download</a>
          </div>
          <svg width={24} viewBox="0 0 512 512" onClick={() => navigate(-1)}>
            <path d="M443.6 387.1 312.4 255.4l131.5-130c5.4-5.4 5.4-14.2 0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4-3.7 0-7.2 1.5-9.8 4L256 197.8 124.9 68.3c-2.6-2.6-6.1-4-9.8-4-3.7 0-7.2 1.5-9.8 4L68 105.9c-5.4 5.4-5.4 14.2 0 19.6l131.5 130L68.4 387.1c-2.6 2.6-4.1 6.1-4.1 9.8 0 3.7 1.4 7.2 4.1 9.8l37.4 37.6c2.7 2.7 6.2 4.1 9.8 4.1 3.5 0 7.1-1.3 9.8-4.1L256 313.1l130.7 131.1c2.7 2.7 6.2 4.1 9.8 4.1 3.5 0 7.1-1.3 9.8-4.1l37.4-37.6c2.6-2.6 4.1-6.1 4.1-9.8-.1-3.6-1.6-7.1-4.2-9.7z" />
          </svg>
        </div>
      </div>
      <div className="flex-1 flex justify-center items-center p-2 pb-8">
        <Splide
          // style={{ height: `calc(${windowSize.height}px - 2.5rem - 4px)` }}
          hasTrack={true}
          options={{
            keyboard: "global",
            paginationKeyboard: true,
            width: `calc((${baseLine}px  - ${padding}) * ${imageRatio})`,
            height: `calc(${baseLine}px -  ${padding})`,
          }}
          aria-label="Brochure"
        >
          <SplideSlide style={{ objectFit: "contain" }}>
            <StaticImage
              src="../images/brochure/1.jpg"
              alt="Brochure"
              formats={["auto", "webp", "avif"]}
            />
          </SplideSlide>
          <SplideSlide>
            <StaticImage
              src="../images/brochure/2.jpg"
              alt="Brochure"
              formats={["auto", "webp", "avif"]}
            />
          </SplideSlide>
          <SplideSlide>
            <StaticImage
              src="../images/brochure/3.jpg"
              alt="Brochure"
              formats={["auto", "webp", "avif"]}
            />
          </SplideSlide>
          <SplideSlide>
            <StaticImage
              src="../images/brochure/4.jpg"
              alt="Brochure"
              formats={["auto", "webp", "avif"]}
            />
          </SplideSlide>
          <SplideSlide>
            <StaticImage
              src="../images/brochure/5.jpg"
              alt="Brochure"
              formats={["auto", "webp", "avif"]}
            />
          </SplideSlide>
          <SplideSlide>
            <StaticImage
              src="../images/brochure/6.jpg"
              alt="Brochure"
              formats={["auto", "webp", "avif"]}
            />
          </SplideSlide>
          <SplideSlide>
            <StaticImage
              src="../images/brochure/7.jpg"
              alt="Brochure"
              formats={["auto", "webp", "avif"]}
            />
          </SplideSlide>
          <SplideSlide>
            <StaticImage
              src="../images/brochure/8.jpg"
              alt="Brochure"
              formats={["auto", "webp", "avif"]}
            />
          </SplideSlide>
          <SplideSlide>
            <StaticImage
              src="../images/brochure/9.jpg"
              alt="Brochure"
              formats={["auto", "webp", "avif"]}
            />
          </SplideSlide>
          <SplideSlide>
            <StaticImage
              src="../images/brochure/10.jpg"
              alt="Brochure"
              formats={["auto", "webp", "avif"]}
            />
          </SplideSlide>
          <SplideSlide>
            <StaticImage
              src="../images/brochure/11.jpg"
              alt="Brochure"
              formats={["auto", "webp", "avif"]}
            />
          </SplideSlide>
          <SplideSlide>
            <StaticImage
              src="../images/brochure/12.jpg"
              alt="Brochure"
              formats={["auto", "webp", "avif"]}
            />
          </SplideSlide>
          <SplideSlide>
            <StaticImage
              src="../images/brochure/13.jpg"
              alt="Brochure"
              formats={["auto", "webp", "avif"]}
            />
          </SplideSlide>
          <SplideSlide>
            <StaticImage
              src="../images/brochure/14.jpg"
              alt="Brochure"
              formats={["auto", "webp", "avif"]}
            />
          </SplideSlide>
          <SplideSlide>
            <StaticImage
              src="../images/brochure/15.jpg"
              alt="Brochure"
              formats={["auto", "webp", "avif"]}
            />
          </SplideSlide>
          <SplideSlide>
            <StaticImage
              src="../images/brochure/16.jpg"
              alt="Brochure"
              formats={["auto", "webp", "avif"]}
            />
          </SplideSlide>
          <SplideSlide>
            <StaticImage
              src="../images/brochure/17.jpg"
              alt="Brochure"
              formats={["auto", "webp", "avif"]}
            />
          </SplideSlide>
          <SplideSlide>
            <StaticImage
              src="../images/brochure/18.jpg"
              alt="Brochure"
              formats={["auto", "webp", "avif"]}
            />
          </SplideSlide>
          <SplideSlide>
            <StaticImage
              src="../images/brochure/19.jpg"
              alt="Brochure"
              formats={["auto", "webp", "avif"]}
            />
          </SplideSlide>
          <SplideSlide>
            <StaticImage
              src="../images/brochure/20.jpg"
              alt="Brochure"
              formats={["auto", "webp", "avif"]}
            />
          </SplideSlide>
          <SplideSlide>
            <StaticImage
              src="../images/brochure/21.jpg"
              alt="Brochure"
              formats={["auto", "webp", "avif"]}
            />
          </SplideSlide>
          <SplideSlide>
            <StaticImage
              src="../images/brochure/22.jpg"
              alt="Brochure"
              formats={["auto", "webp", "avif"]}
            />
          </SplideSlide>
          <SplideSlide>
            <StaticImage
              src="../images/brochure/23.jpg"
              alt="Brochure"
              formats={["auto", "webp", "avif"]}
            />
          </SplideSlide>
          <SplideSlide>
            <StaticImage
              src="../images/brochure/24.jpg"
              alt="Brochure"
              formats={["auto", "webp", "avif"]}
            />
          </SplideSlide>
          <SplideSlide>
            <StaticImage
              src="../images/brochure/25.jpg"
              alt="Brochure"
              formats={["auto", "webp", "avif"]}
            />
          </SplideSlide>
          <SplideSlide>
            <StaticImage
              src="../images/brochure/26.jpg"
              alt="Brochure"
              formats={["auto", "webp", "avif"]}
            />
          </SplideSlide>
          <SplideSlide>
            <StaticImage
              src="../images/brochure/27.jpg"
              alt="Brochure"
              formats={["auto", "webp", "avif"]}
            />
          </SplideSlide>
          <SplideSlide>
            <StaticImage
              src="../images/brochure/28.jpg"
              alt="Brochure"
              formats={["auto", "webp", "avif"]}
            />
          </SplideSlide>
        </Splide>
      </div>
    </div>
  )
}
